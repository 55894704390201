import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Collapse, Nav, DropdownMenu, DropdownItem, DropdownToggle, Navbar, NavbarBrand, NavbarText, NavbarToggler, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap'

const Header = () => {
	const [isOpen, setIsOpen] = useState(false)
	return (
		<section>
			<div className="container-lg" style={{ padding: '0' }}>
				<Navbar light expand="lg">
					<NavbarBrand tag={"div"}>
						<Link className="navbar-brand brand b-color fw-bold" to={'/'}>BitC</Link>
					</NavbarBrand>

					<NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />
					<Collapse navbar isOpen={isOpen}>
						<ul className="navbar-nav ms-auto mb-2 mb-lg-0">
							<li className="nav-item">
								<Link to="/" className="nav-link active" aria-current="page">Home</Link>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="#">Sitemap</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="#">Impressum</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="#">Disclaimer</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="#">Privacy Policy</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="#">About Us</a>
							</li>
						</ul>
					</Collapse>
				</Navbar>
			</div>
		</section>
	)
}

export default Header
