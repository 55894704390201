import React from 'react'
import { useParams } from 'react-router-dom';
import { img } from '../../../assets'

const SubHeader = (props) => {
  let { coin } = useParams();
  coin = coin.toUpperCase();
  return (
    <div className="container-lg px-4 px-lg-0 pt-2">
      <div className="row">
        <div className="col currancy-header">
          <img src={`/data/coin/coinImage/${coin}.png`} alt="" className="img-fluid header-icon" onError={() => <></>} />
          <div className="ps-2 w-100">
            <div className="main-heading d-sm-flex">
              <div>
                <div className="main-title">
                  <span className="pe-2">{props != 'undefined' && props.type && props.type != 'undefined' ? props.type : "___"}  </span>
                  <span onClick={() => { props.setChangeType(!props.changeType) }}
                    style={{ userSelect: 'none' }} className={`badge ${props.profitMoney > 0 ? `bg-profit` : `bg-unprofit`}`}>
                    {props.profitMoney != 'NaN' ? props.profitMoney : '___'}
                    {!props.changeType && " USD"}
                  </span>
                </div>
                <p>Historical {props.type} price chart and current {props.type} price.</p>
              </div>
              <div className="d-flex">
                <span className="price">
                  <span>
                    <span className="b-color fw-bold pe-2">
                      {props.profit}
                    </span>
                    <small>USD /</small><small className='d-md-none'> {props.type && props.type != undefined ? props.type : "___"}</small>
                  </span>
                  <p className='d-none d-md-block'><small> {props.type && props.type != undefined ? props.type : "___"}</small></p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubHeader
