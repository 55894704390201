import React from 'react'
import { img } from '../../../assets'

const Adverisement_2 = () => {
  return (
    <div className="container-fluid text-center pb-4">
      <h2 className="p-3 download-title">
        Bitcoin Pulse Ticker
      </h2>
      <p>Keep track of the blockchain crypto rates in real time.</p>
      <p>This app displays the real time price of cryptocurrencies such as Bitcoin, Ether, Litecoin and over 1000 others.
      </p><p>Have all your favorites clearly at a glance on the main screen.</p>
      <div className="py-3">
        <a href="https://apps.apple.com/app/id1556641476" target="_blank">
          <img src={img.app_store_icon.default} alt="app-store-download" height="64" className="m-2" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.visunia.bitcoin.crypto.ticker" target="_blank">
          <img src={img.play_store_icon.default} alt="play-store-download" height="64" className="m-2" />
        </a>
      </div>
    </div>
  )
}

export default Adverisement_2
