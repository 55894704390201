import moment, { utc } from 'moment';
import React, { useEffect, useState } from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const CustomTooltip = ({ active, payload, label, color }) => {
	const nf = Intl.NumberFormat();

	if (active) {
		return (
			<div className="custom-tooltip">
				<div style={{ color: color }}>{moment.unix(label).format("DD/MM/YYYY HH:mm:ss")}</div>
				<div>Price : {payload && payload[0] && nf.format(payload[0].value)}</div>
			</div>
		);
	}

	return null;
};

const CLineChart = (props) => {
	const { chartData, viewMode } = props;
	const [ticks, setTicks] = useState(7)
	const formatXAxis = (tickFormat) => {
		if (viewMode === 'D') {
			return moment.unix(tickFormat).format('HH:mm:ss')
		}
		return moment.unix(tickFormat).format("DD/MM/YYYY");
	};
	const nf = Intl.NumberFormat('en-US', { maximumFractionDigits: 2 });
	const nf1 = Intl.NumberFormat();

	useEffect(() => {
		if (window.innerWidth >= 678) {
			setTicks(8)
		} else if (window.innerWidth < 678 && window.innerWidth >= 480) {
			setTicks(6)
		} else if (window.innerWidth < 480) {
			setTicks(4)
		}
	}, [])


	useEffect(() => {
		const handleChangeSizeWindow = () => {
			if (window.innerWidth >= 678) {
				setTicks(8)
			} else if (window.innerWidth < 678 && window.innerWidth >= 480) {
				setTicks(6)
			} else if (window.innerWidth < 480) {
				setTicks(4)
			}
		}
		window.addEventListener('resize', handleChangeSizeWindow);
		return () => window.removeEventListener('resize', handleChangeSizeWindow);
	}, [])
	return (
		chartData && chartData.length > 0 ?
			(
				<ResponsiveContainer
					width={'100%'}
					height={480}
				>
					<AreaChart
						width={'100%'} height={'100%'} data={chartData}
					>
						<defs>
							<linearGradient id="colorClose" x1="0" y1="0" x2="0" y2="1">
								<stop offset="5%" stopColor={`${chartData[0].close >= chartData[chartData.length - 1].close ? 'rgba(255, 99, 71, 1)' : '#82ca9d'}`} stopOpacity={1} />
								<stop offset="95%" stopColor={`${chartData[0].close >= chartData[chartData.length - 1].close ? 'rgba(255, 99, 71, 1)' : '#82ca9d'}`} stopOpacity={0} />
							</linearGradient>
						</defs>
						<CartesianGrid strokeDasharray="3 3" />
						<Tooltip
							labelFormatter={(item) => {
								return utc(moment.unix(item)).format('MM/DD/YYYY HH:mm:ss');
							}}
							content={<CustomTooltip color={`${chartData[0].close >= chartData[chartData.length - 1].close ? 'rgba(255, 99, 71, 1)' : '#82ca9d'}`} />}
						/>
						<XAxis
							xAxisId={0}
							interval={0}
							orientation="top"
							dataKey="date"
							type='number'
							domain={[
								'dataMin',
								'dataMax'
							]}
							style={{ fontSize: '12px' }}
							tickCount={ticks}
							tickFormatter={formatXAxis}
						/>
						<YAxis
							yAxisId={0}
							interval={0}
							orientation="left"
							domain={['auto', 'auto']}
							style={{ fontSize: '12px' }}
							tickCount={10}
							tickFormatter={
								(tick) => {
									return `${nf1.format(tick)}$`
								}
							}
						/>
						<YAxis
							yAxisId={1}
							interval={0}
							dataKey="close"
							orientation="right"
							domain={['auto', 'auto']}
							style={{ fontSize: '12px' }}
							tickCount={10}
							tickFormatter={(tick) => {
								return `${nf.format(((tick - chartData[0].close) / chartData[0].close * 100))}%`
							}}
						/>
						<Area
							type="monotone"
							dataKey="close"
							fillOpacity={1}
							stroke={`${chartData[0].close >= chartData[chartData.length - 1].close ? 'rgba(255, 99, 71, 1)' : '#82ca9d'}`}
							fill={`url(#colorClose)`}
						/>
					</AreaChart>
				</ResponsiveContainer>
			)
			: <></>
	)
};

export default React.memo(CLineChart);
