/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LineChart from '../components/LineChart/LineChart';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from './bitcoin/header/Header';
import SubHeader from './bitcoin/subHeader/SubHeader';
import Adverisement_2 from './bitcoin/ads_2/Adverisement_2';
import Footer from './bitcoin/footer/Footer';
import CoinDetails from './bitcoin/CoinDetails/CoinDetails';

const LoadingComponent = () => {
	return <>
		<div className="d-flex flex-column align-items-center justify-content-center p-5">
			<div className="spinner-border" style={{ height: '3rem', width: '3rem' }} role="status" aria-hidden="true">
				<span className="visually-hidden">Loading...</span>
			</div>
			<div className='pt-3'>
				<strong>Loading...</strong>
			</div>
		</div>
	</>
}

const NotFound = () => {
	const navigator = useNavigate();
	return <>
		<div class="d-flex align-items-center justify-content-center p-5 footer-bg">
			<div class="text-center">
				<p class="fs-3"> <span class="text-danger">Opps!</span> Coin not found.</p>
				<p class="lead">
					The coin you’re looking for doesn’t exist.
				</p>
				<p class="lead pb-2">
					Find other coins.
				</p>
				<div onClick={() => { navigator("/") }} class="btn btn-primary">Go Home</div>
			</div>
		</div></>
}

const CoinPage = () => {
	let { coin } = useParams();
	coin = coin.toUpperCase();
	const [currentcyName, setCurrentcyName] = useState({})
	const [chartData, setChartData] = useState([])
	const [viewData, setViewData] = useState('D')
	const [chartData1D, setChartData1D] = useState([])
	const [chartData1Y, setChartData1Y] = useState([])
	// eslint-disable-next-line no-unused-vars
	const [timeUpdate, setTimeUpdate] = useState('')
	const [changeType, setChangeType] = useState(false)
	const [pageStatus, setPageStatus] = useState(3)
	const [minMax52, setMinMax52] = useState({
		min: 0,
		max: 0
	})
	const [allCoin, setAllCoin] = useState()
	const [text, setText] = useState("1 Day")
	const [bitcoin, setBitcoin] = useState({})
	const [active, setActive] = useState(1)
	const getChartData = async (timeStart, timeEnd) => {
		const result = await axios.get(`https://poloniex.com/public?command=returnChartData&currencyPair=USDT_${coin}&start=${timeStart}&end=${timeEnd}&period=300`)
		setChartData(result.data)
		setChartData1D(result.data)
		setActive(1)
	}
	const nf = Intl.NumberFormat();
	const location = useLocation();

	useEffect(() => {
		let some = setInterval(() => {
			getBitcoinData()
		}, 5000)
		return () => clearInterval(some)
	}, [])

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	useEffect(() => {
		const time = moment().format('HH:mm:ss')
		setTimeUpdate(time)
	}, [bitcoin])

	const getBitcoinData = async () => {
		const result = await axios.get(`https://poloniex.com/public?command=returnTicker`)
		if (result.data && result.data[`USDT_${coin}`]) {
			setPageStatus(1)
			setBitcoin(result.data[`USDT_${coin}`])
			setAllCoin(result.data)
		} else {
			setPageStatus(0)
		}
	}

	const getCurrentCurencyName = async () => {
		const result = await axios.get(`https://poloniex.com/public?command=returnCurrencies`)
		setCurrentcyName(result.data[`${coin}`])
	}

	const getChart1Y = async () => {
		const timeStart = moment.utc(moment().subtract(1, 'year')).unix();
		const timeEnd = moment.utc().unix()
		const result = await axios.get(`https://poloniex.com/public?command=returnChartData&currencyPair=USDT_${coin}&start=${timeStart}&end=${timeEnd}&period=1800`)
		setChartData1Y((pre) => result.data)
	}

	const getChartDataByTime = async (timeStart, timeEnd, period = 300) => {
		const result = await axios.get(`https://poloniex.com/public?command=returnChartData&currencyPair=USDT_${coin}&start=${timeStart}&end=${timeEnd}&period=${period}`)
		setChartData(result.data)
	}

	useEffect(() => {
		const timeStart = moment.utc(moment().startOf('date'), true).unix()
		const timeEnd = moment.utc().unix();
		getChartData(timeStart, timeEnd);
		getChart1Y();
		getBitcoinData()
		getCurrentCurencyName()
	}, [coin])


	useEffect(() => {
		if (chartData1Y.length > 0) {
			setMinMax52({
				max: Math.max(...chartData1Y?.map((item) => item.high)),
				min: Math.min(...chartData1Y?.map((item) => item.close))
			})
		}
	}, [chartData1Y])

	return (
		<>
			<Header />
			{(pageStatus === 1) ? <>
				<SubHeader
					changeType={changeType}
					setChangeType={setChangeType}
					profitMoney={
						changeType
							? `${(bitcoin.percentChange * 100).toFixed(2)}%`
							: `${nf.format(parseFloat(bitcoin.last) - (parseFloat(bitcoin.last)) / (1 + parseFloat(bitcoin.percentChange)))}`}
					profit={bitcoin.last ? nf.format(bitcoin.last) : `_`}
					type={`${currentcyName.name}`}
				/>
				<div className="container-lg text-center pt-2">
					<div className="row">
						<div className="col">
							<div className='group-content__right-date'>
								{text}
							</div>
							<LineChart chartData={chartData} viewMode={viewData} />
							<div className='group-content__right-button'>
								<div className={`button ${active === 1 && 'active'}`} onClick={() => {
									const timeStart = moment.utc(moment().startOf('day')).unix()
									const timeEnd = moment.utc().unix()
									setViewData('D')
									getChartDataByTime(timeStart, timeEnd).then(() => {
										setText('1 Day')
										setActive(1)
									})
								}}>1D</div>
								<div className={`button ${active === 2 && 'active'}`} onClick={() => {
									const timeStart = moment.utc(moment().subtract(5, 'days')).unix()
									const timeEnd = moment.utc().unix()
									setViewData('MM')
									getChartDataByTime(timeStart, timeEnd).then(() => {
										setText('5 Days')
										setActive(2)
									})
								}}>5D</div>
								<div className={`button ${active === 3 && 'active'}`} onClick={() => {
									const timeStart = moment.utc(moment().subtract(1, 'month')).unix()
									const timeEnd = moment.utc().unix()
									getChartDataByTime(timeStart, timeEnd, 900).then(() => {
										setText('1 Month')
										setActive(3)
									})
								}}>1M</div>
								<div className={`button ${active === 4 && 'active'}`} onClick={() => {
									const timeStart = moment.utc(moment().subtract(6, 'months')).unix()
									const timeEnd = moment.utc().unix()
									setViewData('Y')
									getChartDataByTime(timeStart, timeEnd, 900).then(() => {
										setText('6 Months')
										setActive(4)
									})
								}}>6M</div>
								<div className={`button ${active === 5 && 'active'}`} onClick={() => {
									const timeStart = moment.utc(moment().subtract(1, 'year')).unix()
									const timeEnd = moment.utc().unix()
									setViewData('Y')
									getChartDataByTime(timeStart, timeEnd, 1800).then(() => {
										setText('1 Year')
										setActive(5)
									})
								}}>1Y</div>
								<div className={`button ${active === 6 && 'active'}`} onClick={() => {
									const timeStart = moment.utc(moment().subtract(5, 'years')).unix()
									const timeEnd = moment.utc().unix()
									setViewData('Y')
									getChartDataByTime(timeStart, timeEnd, 14400).then(() => {
										setText('5 Years')
										setActive(6)
									})
								}}>5Y</div>
								<div className={`button ${active === 7 && 'active'}`} onClick={() => {
									const timeStart = moment.utc(3600).unix()
									const timeEnd = moment.utc().unix()
									setViewData('Y')
									getChartDataByTime(timeStart, timeEnd, 86400).then(() => {
										setText('ALL')
										setActive(7)
									})
								}}
								>ALL</div>
								<div className={`button ${active === 8 && 'active'}`} onClick={() => {
									const timeStart = moment.utc(moment().startOf('year')).unix()
									const timeEnd = moment.utc().unix()
									setViewData('Y')
									getChartDataByTime(timeStart, timeEnd, 900).then(() => {
										setText('YTD')
										setActive(8)
									})
								}}>YTD</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container-lg px-4 px-lg-0 py-4">
					<div className="row">
						<div className="col">
							<div className="block">
								<div className="title pb-3">
									Market Stats
								</div>
								<div className="row px-4 pb-4 pt-0">
									<div className="col-12 col-sm-6 col-md-4 stats">
										Buy / Sell
										<p>{bitcoin.highestBid ? nf.format(parseFloat(bitcoin.highestBid).toFixed(1)) : '_'} / {bitcoin.lowestAsk ? nf.format(parseFloat(bitcoin.lowestAsk).toFixed(1)) : '_'}</p>
									</div>
									<div className="col-12 col-sm-6 col-md-4 stats">
										Low / Height
										<p>{bitcoin.lowestAsk ? nf.format(parseFloat(bitcoin.lowestAsk).toFixed(1)) : '_'} / {bitcoin.highestBid ? nf.format(parseFloat(bitcoin.highestBid).toFixed(1)) : '_'}</p>
									</div>
									<div className="col-12 col-sm-6 col-md-4 stats">
										52 W. Low / Height
										<p>{chartData1D.length > 0 && isFinite(minMax52.min) && isFinite(minMax52.max) ? (`${nf.format(minMax52.min.toFixed(1))} / ${nf.format(minMax52.max.toFixed(1))}`) : <div>_ / _</div>}</p>
									</div>
									<div className="col-12 col-sm-6 col-md-4 stats">
										Open / Close
										<p>{chartData1D.length > 0 ? (`${nf.format(chartData1D[chartData1D.length - 1].open)} / ${nf.format(chartData1D[chartData1D.length - 1].close.toFixed(1))}`) : <div>_ / _</div>}</p>
									</div>
									<div className="col-12 col-sm-6 col-md-4 stats">
										Base Volume
										<p>{bitcoin.baseVolume ? nf.format(bitcoin.baseVolume) : '_'}</p>
									</div>
									<div className="col-12 col-sm-6 col-md-4 stats">
										Quote Volume
										<p>{bitcoin.quoteVolume ? nf.format(bitcoin.quoteVolume) : '_'}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<CoinDetails allCoin={allCoin} coinName={currentcyName.name} />
			</> : (pageStatus === 3) ? <LoadingComponent /> : <NotFound />}
			<Adverisement_2 />
			<Footer />
		</>
	)
}

export default CoinPage
