/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from 'reactstrap'

const CoinDetails = ({ allCoin, coinName }) => {
  const [readmore, setReadmore] = useState(false);
  let { coin } = useParams();
  coin = coin.toUpperCase();
  const [coinData, setCoinData] = useState();
  const [popularAssets, setPopularAssets] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = await axios.get("/data/coin/coinData/" + coin + ".json");
        // let data = await import("../../../data/" + coin + ".json").then(module => module.default);
        setCoinData(data.data);
      } catch (e) {
      }
    }

    const fetchPopularAssets = async () => {
      try {
        let data = await axios.get("/data/popularAssets.json");
        // let data = await import("../../../data/" + coin + ".json").then(module => module.default);
        setPopularAssets(data.data);
      } catch (e) {
      }
    }
    fetchData();
    fetchPopularAssets();
  }, [coin])

  return (
    <div className="container-lg px-4 px-lg-0 py-4">
      <div className="row">
        <div className="col-12 col-lg-4 order-2 order-lg-1 mt-4 mt-lg-0">
          <div className="block">
            <div className="title b-bottom">
              Populer Assets
              <i className="fa-solid fa-star popular-icon"></i>
            </div>
            {popularAssets?.coins?.length > 0 ?
              popularAssets?.coins?.map((c, index) => {
                let code = "USDT_" + c.coinCode.toUpperCase();
                return <Link className="populer-item b-bottom"
                  key={index}
                  to={`/${c.coinCode.toLowerCase()}`}
                >
                  <img src={`/data/coin/coinImage/${c.coinCode.toUpperCase()}.png`} alt={c.coinName} className="img-fluid icon" />
                  <div className="w-100">
                    <div className="populer-item-title">
                      {c.coinName}
                      <span>{allCoin && allCoin[code].last}</span>
                    </div>
                    <div className="populer-item-sub-title">
                      {c.coinCode.toUpperCase()}
                      <span className={`${allCoin && allCoin[code].percentChange * 100 > 0 ? 'profit' : 'loss'}`}>{allCoin ? (allCoin[`USDT_BTC`].percentChange * 100).toFixed(5) : "_"} %</span>
                    </div>
                  </div>
                </Link>
              }
              ) : <></>
            }
          </div>

          <div className="block mt-4">
            <div className="title">
              About BitC
              <i className="fa-solid fa-location-dot popular-icon"></i>
            </div>
            <div className="p-4 pt-0">
              The information on the website does not constitute investment advice or a recommendation to buy.
              The information has been carefully compiled and is regularly updated. However, it is often subject to short-term changes, so that no liability can be assumed for the topicality, correctness, completeness or quality of the information provided.
              <div className='b-bottom py-2'></div>
              <p className="d-flex justify-content-between pt-2">
                <span>Launched :</span>
                <span>2022</span>
              </p>
              {/* <p className="d-flex justify-content-between py-2 b-bottom">
                <span>Users :</span>
                <span>1M+</span>
              </p> */}
            </div>
          </div>
        </div>
        {coinData &&
          <div className="col-12 col-lg-8 order-1 order-lg-2">
            <div className="block mb-4">

              <div className="title">
                More About {coinName}
              </div>
              <div className="content">
                <p>
                  {coinData?.description}
                </p>
                {coinData?.launchedYear && coinData?.founder &&
                  <>
                    <p className="content-title">
                      Who Are the Founders of {coinName}?
                    </p>
                    <p>
                      {coinName} is founded by {coinData?.founder}, in {coinData.launchedYear}.
                    </p>
                  </>
                }

                {!readmore && coinData?.moreDescription && <div id="readmorelink" onClick={() => { setReadmore(true) }}>Read More</div>}
                <div id="more" style={{ display: readmore ? 'block' : 'none' }}>
                  <p>{coinData?.moreDescription}</p>
                  <p className="refrence-url float-end">
                    Data Provided By
                    <a href="https://coinmarketcap.com" target="_blank" rel="noreferrer">CoinMarketCap.com</a>
                  </p>
                  <div id="readmorelink" onClick={() => { setReadmore(false) }} >Read Less</div>
                </div>
              </div>
            </div>
            {coinData?.faqs?.length > 0 &&
              <div className="block">
                <div className="title">
                  FAQ
                </div>
                <UncontrolledAccordion defaultOpen="0">
                  {coinData?.faqs?.map((faq, index) =>
                    <AccordionItem key={index}>
                      <AccordionHeader targetId={index.toString()}>
                        {index + 1}. {faq.question}
                      </AccordionHeader>
                      <AccordionBody accordionId={index.toString()}>
                        {faq.answer}
                        <p className="refrence-url float-end">
                          Data Provided By &nbsp;
                          <a href="https://www.coinbase.com/" target="_blank" rel="noreferrer">www.coinbase.com</a>
                        </p>
                      </AccordionBody>
                    </AccordionItem>
                  )}
                </UncontrolledAccordion>
              </div>
            }
          </div>
        }
      </div>
    </div>
  )
}

export default CoinDetails
