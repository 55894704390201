import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { img } from '../../../assets'
import CookieConsent from 'react-cookie-consent';

const Footer = () => {
	const [data, setData] = useState({});

	useEffect(() => {
		const fetchPopularAssets = async () => {
			try {
				let data = await axios.get("/data/footer.json");
				setData(data.data);
			} catch (e) {
			}
		}
		fetchPopularAssets();
	}, [])

	return (
		<>
			<footer className="font-small footer-bg px-lg-5 p-3">
				<div className="container-lg" id="aboutUs">
					<div className="row">
						<div className="col-12 col-lg-6 text-center text-lg-start">
							<a href="https://apps.visunia.com/Bitcoin-Convertor.html" target="_blank" rel="noreferrer">
								<img src={img.bitcoin_img.default}
									alt="bitcoin-pulse-ticker" width="80" height="80" className="d-inline-block align-top" />
							</a>
							<h5 className="fw-bold pt-2">BitC</h5>
							<p className="">Best Cryptocurrency Website & App</p>
						</div>
						<hr className="d-lg-none my-3" />
						<div className="col-12 col-lg-6">
							<div className="row">
								<div className="col-12 col-md-6 col-lg-4 text-center text-md-start">
									<p className="footer-title">Popular</p>
									<ul className="list-unstyled">
										{data?.popular?.map((c, i) => (
											<li key={i}>
												<Link to={`/${c.coinCode.toLowerCase()}`} className="footer-link">{c.coinName} ({c.coinCode?.toUpperCase()})</Link>
											</li>
										))}
									</ul>
								</div>
								<div className="col-12 col-md-6 col-lg-4 text-center text-md-start">
									<p className="footer-title">Top Trending</p>
									<ul className="list-unstyled">
										{data?.trending?.map((c, i) => (
											<li key={i}>
												<Link to={`/${c.coinCode.toLowerCase()}`} className="footer-link">{c.coinName} ({c.coinCode?.toUpperCase()})</Link>
											</li>
										))}
									</ul>
								</div>
								<div className="col-12 col-md-6 col-lg-4 text-center text-md-start">
									<p className="footer-title">More</p>
									<ul className="list-unstyled">
										<li>
											<a href="mailto:info@visunia.com" className="footer-link">
												<i className="fa fa-envelope-o me-1"></i>
												info@visunia.com
											</a>
										</li>
										<li>
											<a href="www.visunia.com" className="footer-link">
												<i className="fa fa-globe me-1"></i>
												www.visunia.com
											</a>
										</li>
										<li>
											<a href="www.visunia.com/disclaimer" className="footer-link">
												<i className="fa fa-file-text-o me-1"></i>
												Disclaimer
											</a>
										</li>
									</ul>

								</div>
							</div>
						</div>


					</div>
					<div className='w-50'>
						<CookieConsent
							buttonText="OK"
							// debug={true}
							containerClasses="col-lg-12"
							contentClasses="text-capitalize small"
							buttonClasses="btn btn-primary m-2 btn-sm"
							disableButtonStyles={true}>
							We use cookies to provide you with the best online experience. By using our website, you consent to the use of cookies in accordance with our Cookie Policy. <a href='privacy-policy'>Info</a>
						</CookieConsent>
					</div>
				</div>
			</footer>
			<div className="fw-bold font-monospace text-center py-3" style={{ backgroundColor: "#d8d8d8" }}>
				<small>© 2022 Copyright : BitC</small>
			</div>
		</>
	)
}

export default Footer
